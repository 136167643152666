.footer{
    width: 100%;
    
}
.footer-cointainer{
    background-color: rgb(58, 58, 58);
    margin: 0 auto;
    color:white;
    text-align: center;
    width: 100%;
    
}
#first-div{
   margin-left: 5%; 
}
.div-footer{
        display: inline-block;
        width: 20%;
        padding: 2%;
        margin: 0 auto;
        vertical-align: top;
        text-align: left;
}
.div-footer>h1{
    font-size: 30px;
    line-height: 120%;
    margin-bottom: 7%;
}
.div-footer-logo{
    margin-left: 1%;
    width: 70%;
    vertical-align:text-bottom;
}
.footer-span{
    margin-top: 3%;
    line-height: 150%;
}
.div-footer-inline{
    display: inline-block;
    vertical-align:top;
    width: 90%;
}

.div-footer-inline a {
    color: rgb(205, 205, 205);
}

.copy{
    background-color: rgb(37, 37, 37);
    height: auto;
    color:rgb(118, 118, 118);
    height: 5vh;
    font-size: 18px;
}
a {
    text-decoration: none !important;
    color:rgb(118, 118, 118);
  }
  
  a:hover {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
#second-div{
    width: 25%;

}
#second-div>img{
    width: 5%;
}
.footer-img{
    width: 70%;
}
.footer-img-div{
    display: inline-block;
    width: 10%;
}

  @media screen and (max-width: 960px){
    .div-footer{
        width: 70%;
        margin-top:1%;
    }
    #second-div{
        width: 65%;
    }
    .div-footer-logo{
        margin-left: 1%;
        width: 50%;
    }
    .footer-span{
        margin-top:1%;
    }
    #second-div{
        margin-top:-5%;
    }
  }
  @media screen and (max-width: 760px){
    .div-footer{
        width: 70%;
        margin-top:1%;
        font-size: 13px;
        margin-left: 1%;
    }
    .div-footer>h1{
        font-size: 23px;
    }
    .div-footer-logo{
        margin-left: 1%;
        width: 50%;
    }
    .footer-span{
        margin-top:1%;
    }
    #second-div{
        margin-top:-5%;
        font-size: 13px;
    }
    .copy{
        font-size: 13px;
    }
    #second-div{
        width: 80%;
        margin-left: 10%; 
        margin-top: -10%;
    }
    #first-div{
        margin-left: 1%; 
     }
}

@media screen and (max-width: 400px){
    .div-footer{
        width: 70%;
        margin-top:1%;
        font-size: 13px;
        margin-left: 1%;
    }
    .div-footer>h1{
        font-size: 23px;
    }
    .div-footer-logo{
        margin-left: 1%;
        width: 50%;
    }
    .footer-span{
        margin-top:1%;
    }
    #second-div{
        margin-top:-5%;
        font-size: 13px;
    }
    .copy{
        font-size: 13px;
    }
    #second-div{
        width: 80%;
        margin-left: 10%; 
        margin-top: -10%;
    }
    #first-div{
        margin-left: 1%; 
     }
}