*{
  font-family: 'Cairo', sans-serif;
}
* { padding: 0; margin: 0; }
html, body, #fullheight {
    min-height: 100% !important;
    height: 100%;
}
#fullheight {
    width: 250px;
    background: blue;
}
