.about-container{
    margin:0 auto;
    margin-bottom: 5%;;
    margin-top: 5%;;
    color: rgb(62, 62, 62);
  }
  .div-about-top{
    margin:1%;
    margin-left: 15%;;
    padding:1%;
    width:65%;
  }
 .div-about-napis{
    margin:1%;
    margin-top:4%;
    padding:1%;
    display:inline-block;
    width: 90%;
    margin-left: 15%;
 }
 .div-about-napis2{
    margin:1%;
    padding:1%;
    margin-left: 15%;
    display:inline-block;
    width: 30%;
   vertical-align: top;
 }
 #about-div{
   margin-left: 1%;
 }
 .div-about-napis2>p{
    font-weight: bolder;
    font-size: 20px;
 }
 .div-about-napis2> img{
    width: 100%;
    box-shadow: 10px 10px 12px lightgray;
 }

 .div-certyfikaty{    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    
 }
 .div-certyfikaty>a> img{
   
    margin:1%;
    margin-top: 5%;
    height: 30vh;
    width: auto;
    box-shadow: 10px 10px 5px lightgray;
    
 }
 @media screen and (max-width: 1226px) {
   .div-about-napis2{
      width:80% ;
   }
   .div-about-napis2> img{
      margin-left: 15%;
   }
 }

 @media screen and (max-width: 960px) {
   .div-about-top{
       width: 80%;
    margin-left:10%;
   } 
   .div-about-napis2{
      width: 80%;
      margin-left:10%;
   }
   /* .div-certyfikaty>a> img{
      width: 60%;
      margin-left:15%;
   } */
   .div-about-napis2> img{
      margin-left: 15%;
   }
}

@media screen and (max-width: 760px) {
  
  .div-certyfikaty>a> img{
   width: 90%;
   margin-left:5%;
}
.div-about-napis2> p{
   width: 90%;
   margin-left:2%;
   font-size: 16px;
}
.div-about-top>h1{
   font-size: 25px;
}
.div-about-napis> img{
   margin-top: 100%;
   margin-left: -15%;
}
}
@media screen and (max-width: 760px) {
  
   /* .div-certyfikaty>a> img{
    width: 90%;
    margin-left:5%;
 } */
 .div-about-napis2> p{
    width: 90%;
    margin-left:2%;
    font-size: 16px;
 }
 .div-about-top>h1{
    font-size: 25px;
 }
 .div-about-napis> img{
    margin-top: 150%;
    margin-left: -15%;
 }
 }

 @media screen and (max-width: 400px) {
   .div-about-napis> img{
      margin-top: 250%;
      margin-left: -15%;
   }
   }