table {
    border-collapse: collapse;
    width: 100%;
    display:inline-block;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  th, td {
    text-align: left;
    padding: 8px;
    font-size: 20px;
  }
  
  tr:nth-child(even){background-color: #dadada}
  
  tr:nth-child(odd){background-color: #ffffff;}
  
  th {
    background-color: #06da62;
    color: white;
  }

  .services-container{
    margin:0 auto;
    margin-bottom: 5%;;
    margin-top: 5%;;
    color: rgb(62, 62, 62);
  }
  .div-services-top{
    margin:1%;
    margin-left: 15%;;
    padding:1%;
    width:65%;
  }
 .div-services-table{
    margin:1%;
    margin-left:18%;
    padding:1%;
    display:inline-block;
    width:60%;
    
 }
 .div-services-image>img{
    width:60%;
 }
 .div-services-image{
    display:inline-block;
    width:30%;
    vertical-align: bottom;
 }
 @media screen and (max-width: 960px) {
  .div-services-table{
    width: 80%;
    margin-left:10%;
  }
  .div-services-top{
    width: 80%;
    margin-left:10%;
  }

}

@media screen and (max-width: 760px) {
  .div-services-table{
    width: 80%;
    margin-left:10%;
   
  }
  td{
     font-size: 13px;
  }
  .div-services-top{
    width: 80%;
    margin-left:10%;
  }
  .div-services-top>h1{
    font-size: 25px;
  }
}

@media screen and (max-width: 400px) {
  .div-services-table{
    width: 80%;
    margin-left:10%;
   
  }
  td{
     font-size: 13px;
  }
  .div-services-top{
    width: 80%;
    margin-left:10%;
  }
  .div-services-top>h1{
    font-size: 25px;
  }
}