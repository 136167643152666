@import "react-slideshow-image/dist/styles.css";
.slide-cointainer{
    color:rgb(62, 62, 62);
}
.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    width: 100%;
  }
  .each-slide .div-text {
    position: absolute;
    padding: 2%;
    font-size: 25px;
    text-align: left;
   width: 100%;
    top:20%;
    left:14%;
    color:rgb(62, 62, 62);
  }
  .each-slide p{
    width: 30%;
    font-size: 23px;
    word-wrap: break-word;
  }
  .each-slide{
    position: relative;
  }
  .each-slide img{
    width: 100%;
    margin-left: 25%;
 margin-top: 10%;
  }
  .button {
    border: 3px solid black;
    background-color: white;
    color: black;
    padding: 14px 28px;
    font-size: 22px;
    cursor: pointer;
    margin-top: 0.5%;
    border-color: #06da62;
    color: rgb(62, 62, 62);
  }
  .button:hover {
    background-color: #06da62;
    color: rgb(62, 62, 62);
  }

  .home-kafle{
    display: inline-block;
    vertical-align:top;
    text-align: center;
    width:100%;
    color: rgb(62, 62, 62);
    margin-top: -2%;
  }
.home-kafle h1{
    width: 50%;
    font-size: 50px;
    padding:2%;
    margin: 0 auto;
  
}
  .kafle {
    vertical-align:top;
    display: inline-block;
    background-color: #ebebeb;
    width: 20%; margin: 0 auto;
    height: 70%;
    margin:1%;
    padding: 1%;
   margin-bottom: 2%;
    text-align: center;
    border: 2px solid #ebebeb;
    border-radius: 5%;
}
.kafle>img{
    width: 30%;
}
#p-div{
  width: 100%;
}
#p-div-big{
  width: 100%;
  font-size: 35px;
}
@media screen and (max-width: 1170px){
  .each-slide img{
    width: 100%;
    margin-left: 25%;
    margin-top: 30%;
  }
}
@media screen and (max-width: 960px){

  .div-text >p{
     font-size: 20px;
     text-align: left;
    width: 80%;
     top:5%;
     left:14%;
     color:rgb(62, 62, 62);
     
   }
   .div-text {
    width: 80%;
   }
   #p-div{
     width: 80%;;
   }
   #p-div-big{
    width: 80%;
    font-size: 35px;
  }
   .each-slide .span-div{
 margin-top: -10%;
   }
   .each-slide img{
     width: 100%;
     margin-left: 25%;
     margin-top: 50%;
   }
 
   .home-kafle >h1{
     width:100%;
   }
   .kafle {
     vertical-align:top;
     display: inline-block;
     background-color: #ebebeb;
     width: 50%; margin: 0 auto;
     height: 70%;
     margin:1%;
     padding: 1%;
    margin-bottom: 2%;
     text-align: center;
 }
}
@media screen and (max-width: 760px){
  .div-text >p{
    font-size: 16px;
    text-align: left;
   width: 80%;
    left:14%;
    color:rgb(62, 62, 62);
    
  }
  .home-kafle h1{
    width: 70%;
  }
  #p-div{
    width: 80%;
  }
  #p-div-big{
    width: 80%;
    font-size: 22px;
  }
  .each-slide .span-div{
    margin-top:-20%;
      }
      .each-slide img{
        width: 100%;
        margin-left: -10%;
        margin-top: 90%;
      }
    
  .home-kafle >h1{
    font-size: 30px;
  }
  .kafle {
    vertical-align:top;
    display: inline-block;
    background-color: #ebebeb;
    width: 75%;
    font-size: 15px;
  }
  .button {

    font-size: 15px;
    margin-top:5%;
    width: 70%;
  }
}
@media screen and (max-width: 400px){
  .div-text >p{
    font-size: 16px;
    text-align: left;
   width: 80%;
    left:14%;
    color:rgb(62, 62, 62);
    
  }
  #p-div{
    width: 80%;
  }
  #p-div-big{
    width: 80%;
    font-size: 22px;
  }
  .each-slide .span-div{
    margin-top:-25%;
      }
      .each-slide img{
        width: 100%;
        margin-left: -10%;
        margin-top: 120%;
      }
    
  .home-kafle >h1{
    font-size: 30px;
  }
  .kafle {
    vertical-align:top;
    display: inline-block;
    background-color: #ebebeb;
    width: 75%;
    font-size: 15px;
  }
  .button {

    font-size: 15px;
    margin-top:5%;
    width: 70%;
  }
}