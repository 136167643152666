.navbar-top{
  background-color: #dddddd;
  margin:0 auto;
  text-align: right;
  color: rgb(75, 75, 75);
}
.navbar-contact{
  text-align: right;
  display: inline-block;
  width: 40%;
}
.navbar-contact-left{
  text-align: left;
  display: inline-block;
  width: 40%;
  margin-left: 1%;
  margin-right: 1%;
}
.navbar-pasek{
  display: inline-block;
  width: 100%;
  background-color:  #ebebeb;
  margin:0 auto;
}
.navbar-name{
margin-left: 16%;
  width:40%;
  float:left;
  color: grey;
}
.navbar{
  text-align: center;
 
}
.topnav {
  overflow: hidden;
  margin-left: 53%;
}

.topnav a {
  float: left;
  display: block;
  text-align: center;
  padding: 2% 2%;
  margin-left:1%;
  font-size: 19px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
}

.topnav a:hover {
  color:rgb(62, 62, 62);
  border-bottom: 3px solid rgb(62, 62, 62);
}

.topnav a.active {
  color:rgb(62, 62, 62);
  border-bottom: 3px solid rgb(62, 62, 62);
}
#contact-images{
  width: 3%;
}
.div-navbar-inline{

  display: inline-block;
  vertical-align:middle;
}
@media screen and (max-width: 960px){
  .navbar-contact{
    width: 90%;
    margin-right: 5%;
  }
  .navbar-contact-left{
    width: 90%;
    margin-right: 5%;
    text-align: right;
  }
  .navbar-name{
      width:80%;
    }
    
  .topnav {
    margin-left: 15%;
    width: 80%;
  }
}
@media screen and (max-width: 760px){
  .navbar-contact{
    width: 90%;
    margin-left: 1%;
    font-size: 12px;
  }
  .navbar-contact-left{
    width: 90%;
    margin-right: 1%;
    font-size: 12px;
    margin-top: -2%;
  }
    .topnav> a{
       font-size: 15px;
    }
  }
    @media screen and (max-width: 400px){
      .navbar-contact{
        width: 90%;
        margin-left: 1%;
        font-size: 12px;
      }
      .navbar-contact-left{
        width: 90%;
        margin-right: 1%;
        font-size: 12px;
        margin-top: -2%;
      }
      .navbar-name{
          font-size: 10px;
        }
        .topnav> a{
           font-size: 15px;
        }
    }
