
  .contact-container{
    margin:0 auto;
    margin-bottom: 5%;;
    margin-top: 5%;;
    color: rgb(62, 62, 62);
  }
  #required:after {
    content:" *";
    color: red;
  }
 .div-contact1{
    margin:1%;
    margin-left: 15%;;
    padding:1%;
    display:inline-block;
    width: 25%;
    vertical-align:top;
    
 }
.div-contact1 a {
  color: #06da62;
}

.div-contact1 a:hover {
  color: gray;
}
  
 .div-contact2{
    margin:1%;
    padding:1%;
    display:inline-block;
    width: 35%;
 }
  .screen-body {
    display: flex;
  }
  
  .screen-body-item {
    flex: 1;
    padding:1%;
    margin-top: 1%;;
  }
  
  .screen-body-item.left {
    display: flex;
    flex-direction: column;
  }
  
  .app-form-group {
    margin-bottom: 15px;
  }
  
  .app-form-group.buttons {
    margin-bottom: 0;
  }
  
  .app-form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid rgb(62, 62, 62);
    color: rgb(97, 97, 97);
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
    transition: border-color .2s;
  }
  
  .app-form-control::placeholder {
    color: rgb(62, 62, 62);
  }
  
  .app-form-control:focus {
    border-bottom-color: rgb(97, 97, 97);
  }
  
  .app-form-button {
    width: 100%;
    padding: 14px 28px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 1%;
  }
  #checkbox-kontakt{
    accent-color: #34495e;
  }
  .div-contact-form-checkbox{
    vertical-align: bottom;
  }
  .contact-image{
    margin-top: 10%;
    
  }
  .contact-image>img{
    width: 100%;
    box-shadow: 10px 10px 12px lightgray;
  }
  @media screen and (max-width: 960px) {
  
    .screen-body-item.left {
      margin-bottom: 30px;
    }
    .screen-body {
      padding: 40px;
    }
  
    .screen-body-item {
      padding: 0;
    }
    .div-contact1{
      width: 80%;
      margin-left:10%;
      margin-top: 1%;
    }
    .div-contact2{
      width: 80%;
      margin-left:10%;
      margin-top: 1%;
    }
    .contact-image>img{
      width: 80%;
      
    }
    .contact-image{
      margin-top: 5%; 
     
   }
  }
  
  @media screen and (max-width: 760px) {
    .screen-body {
      padding: 40px;
    }
  
    .screen-body-item {
      padding: 0;
    }
    .div-contact1{
      width: 80%;
      margin-left:10%;
      margin-top: 1%;
    }
    .div-contact2{
      width: 80%;
      margin-left:10%;
      margin-top: 1%;
    }
    .div-contact1>h1{
      font-size: 25px;
    }
    .div-contact2>h1{
      font-size: 25px;
    }
    .div-contact1>h2{
      font-size: 16px;
    }
    .div-contact1>h3{
      font-size: 16px;
    }
    .contact-image>img{
      width: 100%;
      
    }
  }
  @media screen and (max-width: 400px) {
    .screen-body {
      padding: 40px;
    }
  
    .screen-body-item {
      padding: 0;
    }
    .div-contact1{
      width: 80%;
      margin-left:10%;
      margin-top: 1%;
    }
    .div-contact2{
      width: 80%;
      margin-left:10%;
      margin-top: 1%;
    }
    .div-contact1>h1{
      font-size: 25px;
    }
    .div-contact2>h1{
      font-size: 25px;
    }
    .div-contact1>h2{
      font-size: 16px;
    }
    .div-contact1>h3{
      font-size: 16px;
    }
    .contact-image>img{
      width: 100%;
      
    }
  }
  